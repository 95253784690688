import { useState } from "react";
import React from 'react';
import Footer from "../../components/footer";
import Header from "../../components/header";
// import { isMobile } from "../../libs/userAgent";
import "./index.css";


const Home = (): JSX.Element => {
  const [address, setAddress] = useState('');
  const [isConnected, setIsConnected] = useState(false);


  return (
    <>
      <div className="page">
        <Header></Header>
        <div className="home" id="Home">
          <div className="banner">
            <h1 className="title">
              <img src={require('../../assets/banner-title.png')} alt="" />
            </h1>
            <div className="about">
              <h3>About Us</h3>
              <p>JustIntel (JIT) is a decentralized, trust-based social platform which allows users to connect effectively with projects and each other, while constructing comprehensive digital identities. The social connections are facilitated by on-chain data mining and big data algorithms. All the transaction data on JIT, including ratings, will be analyzed to generate valuable and actionable Web3 “Intels” which will be stored on-chain for permissionless accessibility.</p>
            </div>
          </div>
          <div className="airdrop" id="Airdrop">
            <div className="contain">
              <div className="text">
                <h2><span>$</span> JIT Airdrop</h2>
                <p>Check your eligibility to  claim</p>
                <div className="progress-box">
                  <div className="progress">
                    <div className="count"></div>
                  </div>
                  <div className="num">
                    20 / 1000
                  </div>
                </div>
                <button className="claim" >
                  claim
                </button>
              </div>
              <div className="pic">
                <img src={require('../../assets/airgrop-pic.png')} alt="" />
              </div>
            </div>
          </div>
          <div className="coupon">
            <h1 className="blockTile">JIT Coupon Utilities</h1>
            <div className="hotAir">
              <img src={require('../../assets/hotAir.png')} alt="" />
            </div>
            <div className="list">
              <div className="item">
                <div className="content">
                  <div className="num">
                    1.
                  </div>
                  <div className="text">
                    Priority access to special outfits in subsequent "Digital Soul" products.
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="num">
                    2.
                  </div>
                  <div className="text">
                    Free airdrops of parts of the "Digital Soul" outfits.
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="num">
                    3.
                  </div>
                  <div className="text">
                    Entitlement to anti-dilution airdrops each time the token inflates.
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="content">
                  <div className="num">
                    4.
                  </div>
                  <div className="text">
                    When new features, services, or products are launched, coupon holders can have priority usage rights.
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="mission">
                <div className="title">
                  <div className="bg">
                  </div>
                  <div className="title-text">
                    Justintel
                  </div>

                </div>
                <div className="content">
                  <div className="subtitle">
                    <span>mission</span>
                  </div>
                  <p>At JustIntel, our vision is to become the beacon of trust in the trustless world of Decentralization, where individuals and communities can confidently engage and collaborate with complete peace of mind.
                  </p>
                </div>
                <div className="star">
                  <img src={require('../../assets/star.png')} alt="" width={'100%'} />
                </div>
              </div>
              <div className="vision">
                <div className="title">
                  <div className="bg">
                  </div>
                  <div className="title-text">
                    Justintel
                  </div>

                </div>
                <div className="content">
                  <div className="subtitle">
                    <span>Vision</span>
                  </div>
                  <p>Our mission is to empower individuals and communities by providing the tools, knowledge, and decentralized platform necessary to navigate and thrive in the trustless landscape of Web3.
                  </p>
                </div>
                <div className="star">
                  <img src={require('../../assets/star.png')} alt="" width={'100%'} />
                </div>
              </div>

              <div className="bgstar">
                <img src={require('../../assets/star.png')} alt="" width={'100%'} />
              </div>
            </div>
          </div>
          <div className="JustIntel" id="Product">
            <h1 className="blockTile">JustIntel</h1>
            <p className="subtext">
              Trust-based Social Platform with Actionable Intelsntel
            </p>
            <div className="productlist">
              <div className="top">
                <div className="left">
                  <div className="text">
                    <h4 className="title">REAL UTILITIES</h4>

                    <h5 className="subtitle">Credibility Score: </h5>
                    <p>Calculated using machine learning algorithms based on user info and ratings.
                    </p>
                    <h5 className="subtitle">Social:  </h5>
                    <p>Enable messaging, comments, and interaction among friends.
                    </p>

                  </div>

                  <div className="img">
                    <img src={require('../../assets/product1.png')} alt="" width={'100%'} />
                  </div>
                </div>
                <div className="right">
                  <div className="img">
                    <img src={require('../../assets/product1.png')} alt="" width={'100%'} />
                  </div>
                  <div className="text">
                    <h4 className="title">REAL UTILITIES</h4>

                    <h5 className="subtitle">Credibility Score: </h5>
                    <p>Calculated using machine learning algorithms based on user info and ratings.
                    </p>
                    <h5 className="subtitle">Social:  </h5>
                    <p>Enable messaging, comments, and interaction among friends.
                    </p>

                  </div>
                </div>
              </div>
              <div className="center">
                <div className="list">
                  <div className="item item1">
                    <img src={require('../../assets/product3.png')} alt="" width={'100%'} />
                  </div>
                  <div className="item item2">
                    <img src={require('../../assets/product4.png')} alt="" width={'100%'} />
                  </div>
                  <div className="item">
                    <img src={require('../../assets/product5.png')} alt="" width={'100%'} />
                  </div>
                </div>
              </div>
              <div className="bottom">
                <div className="text">
                  <h4 className="title">Robust Social Interactions</h4>
                  <p>JustIntel offers seamless access to information across channels, enabling users to express their views anytime, anywhere. Users can sync their actions with social platforms and actively participate in rating and commenting on others' credibility, fostering transparency and trust through meaningful interactions.</p>
                </div>
              </div>
            </div>
            <div className="digital">
              <div className="text">
                Reinforcing Synergies among
                info, rating and digital soul
              </div>
              <div className="img">
                <img src={require('../../assets/digital.png')} alt="" width={'100%'} />
              </div>
            </div>
          </div>
          <div className="kol">
            <div className="quan"></div>
            <div className="img">
              <img src={require('../../assets/KOL.png')} alt="" width={'100%'} />
            </div>
          </div>
          <div className="tokenomics" id="Tokenomics">
            <div className="jit">
              <div className="des">
                JIT Coupon is a novel element in JustIntel, offering exclusive utilities and limitless possibilities. With $JIT, our community gains access to specialized services, thrilling activities, and coveted privileges, setting us apart.It ignites community spirit, fuels engagement, and adds undeniable value to every member's journey.
              </div>
              <h4 className="title">JIT Tokenomics</h4>
            </div>
            <div className="chat">
              <div className="left">
                <img src={require('../../assets/chat.png')} alt="" />
              </div>
              <div className="right">
                <div className="text">
                  JIT Token comprise an initial issuance of 20% of the total supply, with subsequent monthly airdrops and an extra 6% anti-dilution Coupon for JIT Coupon holders. The final distribution plan allocates tokens for Rug Victims (5%), Community Members (30%), Ecosystem Development (15%), Team and Early Contributors (20%), Treasury (20%), and Advisors and Partners (10%). These tokenomics ensure trust restoration, reward loyalty, drive ecosystem growth, secure project sustainability,and attract valuable partnerships. JIT Token is poised to create a vibrant community, enhance value for participants, and establish a solid foundation for long-termsuccess in the Web3 space.
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap">
            <div className="blockTile">Road Map</div>
            <div className="list">
              <div className="item item1">
                <h4>PRE-LAUNCH: BUILD COMMUNITY</h4>
                <p>We aim to create a vibrant and engaged community by fostering connections, providing valuable resources, and nurturing meaningful interactions, laying the foundation for shared success in our project.</p>
              </div>
              <div className="item item2">
                <h4>V1 PRODUCT RELEASE</h4>
                <p>Launch essential features including messaging, posting, and rating, enabling users to connect and engage. Additionally, personalized digital identities and a credibility system will be introduced, fostering transparency and individuality within our platform.</p>
              </div>
              <div className="item item3">
                <h4>V2 PRODUCT UPGRADE</h4>
                <p>Improve key functions by implementing a robust rating system, NFTizing personal accessories, integrating digital identities, expanding to other ecosystems, and creating sub-communities based on user interests. These enhancements will elevate user experience and foster a dynamic and engaging platform.</p>
              </div>
              <div className="item item4">
                <h4>V3 PRODUCT Commericialize</h4>
                <p>We will generate actionable insights applicable to social, credit evaluation, career, and investment domains. Additionally, we will collaborate with partners to develop tailored products utilizing our insights for mutual benefit.</p>
              </div>
            </div>
          </div>
          <div className="ecosystem">
            <div className="left">
              <div className="blockTile">Road Map</div>
              <p>JustIntel’s products aim to produce utility for and build trust among users and institutions</p>
            </div>
            <div className="right">
              <img src={require('../../assets/ecosystem.png')} alt="" />
            </div>
          </div>

        </div>
        <Footer></Footer>
        <div className="bottom-quan"></div>
      </div>
    </>
  );
}

export default Home;

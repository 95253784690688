import "./index.css";
import React from 'react';
import discord from '../../assets/discord.png'
import LINK from '../../assets/link.png'
import twitter from '../../assets/twitter.png'
import Mirror from '../../assets/mirror.png'
// import ins from '../../assets/social/ins.png'
// import medium from '../../assets/social/medium.png'

const Footer = () => {
  return (
    <div className="footer_cus">
      <div className="container">
        <div
          className="top"
        >
          <div className="footer_logo">
            <img
              src={require('../../assets/logo-footer.png').default}
              alt=""
            />
          </div>
          <div className="footer_info">
            Building Trust in the Trustless World
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="list">
              <div className="footer_link copy_right" id='copyright'>© 2023 NXG Labs. All rights reserved.</div>
              <a href="/privacy_policy" className="footer_link">
                <span>Privacy policy</span>
              </a>
              <a href="/terms" className="footer_link">
                <span>Terms of service</span>
              </a>

            </div>
          </div>
          <div className="right">
            <div className="social_group">
              <a href="javascript();" target={'_blank'} className="item">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="javascript();" target={'_blank'} className="item">
                <img src={discord} alt="discord" />
              </a>
              <a href="javascript();" target={'_blank'} className="item">
                <img src={LINK} alt="Link" />
              </a>
              <a href="javascript();" target={'_blank'} className="item">
                <img src={Mirror} alt="Mirror" />
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
